modules.subMenu = (function() {

    var options = {
        rootElement: null,
        height: 50,
        offsetMargin: 5,
        hash: "",
        subMenuElements: [],
        subMenuTargetElements: [],
        subMenuMaxLeft: 0,
        swipeOffset: 320
    };

    function checkAnchorSelected() {
        var flagBreak = false,
            windowScrollTop = $(window).scrollTop() + options.height + options.offsetMargin;
        $(options.subMenuTargetElements).each(function(index, _this) {
            if (!flagBreak && _this.is(":visible")) {
                var offsetTop = _this.offset().top,
                    offsetBottom = offsetTop + _this.outerHeight(true);

                if (offsetTop <= windowScrollTop && windowScrollTop < offsetBottom) {
                    flagBreak = true;
                    if (!$(options.subMenuElements[index]).closest("li").hasClass("active")) {
                        options.subMenuElements.each(function() {
                            $(this).closest("li").removeClass("active");
                        });

                        $(options.subMenuElements[index]).closest("li").addClass("active");
                        moveToOption();
                    }
                }
            }
        });
    }

    function moveToOption() {
        options.rootElement.find("ul").animate({
            scrollLeft: options.rootElement.find("li.active").position().left
        }, 500);
    }

    function _initSubMenu() {

        options.hash = window.location.hash;
        options.subMenuElements = $(".sub-menu [href^='#']");
        options.subMenuElements.each(function() {
            options.subMenuTargetElements.push($($(this).attr("href")).parent());
        });

        var subMenuFullWidth = $(".sub-menu ul").width();
        //Calculate boundries
        var subMenuWrapperElement = $(".sub-menu-wrapper"),
            subMenuWrapperFullWidth = subMenuWrapperElement.outerWidth(),
            leftVal = -(subMenuFullWidth - subMenuWrapperFullWidth);

        options.subMenuMaxLeft = leftVal;

        if(leftVal < 0) {
            subMenuWrapperElement.pep({
                axis: 'x',
                constrainTo: [0, 0, 0, leftVal],
                useCSSTranslation: false,
                allowDragEventPropagation: true,
                shouldPreventDefault: false
            });

            $(".sub-menu-wrapper").on('mousedown mousemove', function(e){
                e.preventDefault();
            });

            //Events arrows scroll
            $(".sub-menu-nav-arrows .arrow").on("click", function() {

                var leftArrow = true,
                    element = $(this);

                if (element.hasClass("right")) {
                    leftArrow = false;
                }

                var val = 0;
                if (leftArrow) {
                    val = (parseInt(subMenuWrapperElement.css("left")) + options.swipeOffset);
                    if (val > 0) {
                        val = 0;
                    }
                } else {
                    val = (parseInt(subMenuWrapperElement.css("left")) - options.swipeOffset);
                    if (val < leftVal) {
                        val = leftVal;
                    }
                }
                subMenuWrapperElement.css("left", val + "px");
            });

        }else{
            $(".sub-menu .arrows-container").addClass('hide');
        }

    }

    function _initEvents() {
        if (options.subMenuTargetElements.length > 0) {
            $(document).on("scroll", function() {
                checkAnchorSelected();
            });

            options.subMenuElements.on("click", function() {
                checkAnchorSelected();
            });
        }
    }

    function _start() {
        if (options.subMenuTargetElements.length > 0) {
            options.rootElement = $(".sub-menu");
            checkAnchorSelected();
            if (options.rootElement.find("li.active").length === 0 && options.subMenuElements.length > 0) {
                $(options.subMenuElements[0]).closest("li").addClass("active");
            }
        }

        var subMenuElement = $('[data-sub-menu-active-item]');

        if(subMenuElement.length){

            var subMenuActiveElem =  $(subMenuElement.attr('data-sub-menu-active-item'));

            if(subMenuActiveElem.length){
                var elLeft = -$(subMenuElement.attr('data-sub-menu-active-item')).position().left;

                if(elLeft < options.subMenuMaxLeft ) {
                    elLeft =  options.subMenuMaxLeft;
                }

                subMenuElement.css('left', elLeft);
            }

        }

    }

    function init() {

        $(document).ready(function() {
            _initSubMenu();
            _initEvents();
            _start();
        });
    }

    init();

    return {};

})();
