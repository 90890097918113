modules.scrollToContentFixed = (function () {

    var options = {};


    function _scrollCheck(element, offsetTop) {
        var windowOffset = $(window).scrollTop();

        if (offsetTop >= windowOffset) {
            element.removeClass("scroll-to-content-fixed");
        } else {
            element.addClass("scroll-to-content-fixed");
        }
    }

    /*
     * Make content fixed to top as soon has it gets to the top of the page.
     */
    function _init() {

        $(".sticky-scroll-content").each(function () {
            var element = $(this),
                    offsetTop = element.offset().top;

            $(document).on("scroll", function () {
                _scrollCheck(element, offsetTop);
            });
            // Force Initial Check
            _scrollCheck(element, offsetTop);
        });
    }

    _init();

    return {};

})();
